import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import classNames from "classnames"
import styles from "../styles/modules/header.module.sass"

export default ({ page }) => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(query)

  return (
    <>
      <header className={classNames(styles.header, "slipnote-section-small")}>
        <div className="slipnote-container slipnote-text-center">
          <h1 className={styles.title}>
            <Link to="/">{title}</Link>
          </h1>
          {/* <p className={styles.description}>{description}</p> */}
        </div>
      </header>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
