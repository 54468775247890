import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import styles from "../styles/modules/recentlyWidget.module.sass"

export default () => {
  const {
    recentlyAllMarkdownRemark: { edges: recentPosts },
  } = useStaticQuery(query)

  return (
    <>
      <ul className={styles.recently}>
        {recentPosts.map(
          ({
            node: {
              id,
              frontmatter: { title },
              fields: { slug },
            },
          }) => (
            <li key={id}>
              <Link to={slug}>{title}</Link>
            </li>
          )
        )}
      </ul>
    </>
  )
}

export const query = graphql`
  query {
    recentlyAllMarkdownRemark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
