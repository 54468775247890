import React from "react"
import { Link } from "gatsby"
import styles from "../styles/modules/tag.module.sass"

export default ({ tag }) => (
  <>
    <Link to={`/${tag}`} className={styles.tag}>
      <img
        className={styles.icon}
        src={`/assets/logo/${tag.toLowerCase()}.png`}
        alt="*"
      />
      {tag}
    </Link>
  </>
)
