import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "../styles/modules/tagsWidget.module.sass"
import classNames from "classnames"

//- components
import Tag from "../components/Tag"

export default () => {
  const {
    tagsAllmarkdownRemark: { group: tags },
  } = useStaticQuery(query)

  return (
    <>
      <div className={classNames(styles.tags)}>
        {tags.map(({ tag, totalCount }) => (
          <Tag key={tag} tag={tag} />
        ))}
      </div>
    </>
  )
}

export const query = graphql`
  query {
    tagsAllmarkdownRemark: allMarkdownRemark {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`
