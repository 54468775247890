import React from "react"
// import styles from "../styles/modules/main.module.sass"
// import classNames from "classnames"

export default ({ children, header, sidebar, footer }) => (
  <div>
    {header}
    <div className="slipnote-container">{children}</div>
    {footer}
  </div>
)
