import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { globalHistory } from "@reach/router"
import StructuredData from "./StructuredData"
import moment from "moment"

export default ({ frontmatter }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query)

  //- meta
  const route = globalHistory.location.pathname
  const pageTitle =
    route === "/"
      ? `WEBのフセン | ${siteMetadata.title}`
      : `${frontmatter?.title ?? route.slice(1)} | ${siteMetadata.title}`
  const pageDescription =
    route === "/"
      ? siteMetadata.description
      : frontmatter?.description ??
        `${route.slice(1)}を学んだときのメモ一覧です`
  const url = `${siteMetadata.siteUrl}${frontmatter?.route ?? ""}`
  const image =
    frontmatter != null
      ? `${siteMetadata.siteUrl}${frontmatter.thumbnail.childImageSharp.fluid.src}`
      : "https://slipnote.net/assets/icons/logo.jpg"
  const date = frontmatter?.date ?? moment()

  //- view
  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        title={pageTitle}
        meta={[
          {
            name: "description",
            content: pageDescription,
          },
        ]}
      >
        <script type="application/ld+json">
          {JSON.stringify(
            StructuredData({ pageTitle, pageDescription, url, date, image })
          )}
        </script>
      </Helmet>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
