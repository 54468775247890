import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "../styles/modules/footer.module.sass"
//- widgets
import TagsWidget from "../widgets/TagsWidget"
import RecentlyWidget from "../widgets/RecentlyWidget"
import { globalHistory } from "@reach/router"

export default () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(query)

  const route = globalHistory.location.origin

  return (
    <>
      <div className="slipnote-section slipnote-divider-top">
        <div className="slipnote-container slipnote-text-center">
          <nav>
            <div className={styles.container}>
              <h5 className={styles.title}>TAGS</h5>
              <TagsWidget />
            </div>

            <div className={styles.container}>
              <h5 className={styles.title}>RECENTLY</h5>
              <RecentlyWidget />
            </div>
          </nav>
          <div className="slipnote-section-small">
            <a href={route + "/about"}>About</a>
          </div>
          <div className={styles.copyright}>
            <span>
              &copy;&nbsp;{title}. Powered by&nbsp;
              <a
                href="https://www.gatsbyjs.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gatsby
              </a>
              .
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
