import React from "react"
import Head from "../components/Head"
import Header from "../components/Header"
import Main from "../components/Main"
import Footer from "../components/Footer"

export default ({ page = "default", children, frontmatter }) => {
  return (
    <>
      <Head frontmatter={frontmatter} />
      <Main header={<Header page={page} />} footer={<Footer />}>
        {children}
      </Main>
    </>
  )
}
